import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Zap, DollarSign, Lock, Users, Shield, CheckCircle, Mail } from 'lucide-react';

const VulnerabilityConsequencesAndSolutionsSection = () => {
  const [email, setEmail] = useState('');

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  const harmScenarios = [
    { icon: Zap, title: "Data Breaches", description: "Attackers can access sensitive corporate and customer information. These breaches often cause big financial losses for companies. They also hurt the business's reputation and reduce customer trust." },
    { icon: DollarSign, title: "Financial Losses", description: "Attackers can manipulate chatbot responses to mislead users. This can trick employees or customers into making incorrect financial transactions or reporting inaccurate numbers. Such errors often result in significant monetary losses for the company." },
    { icon: Lock, title: "Reputation Damage", description: "Attackers can alter chatbot responses to spread harmful information. The compromised chatbot might insult users, share false statements about the company, or make inappropriate comments about executives or competitors. These manipulated responses can quickly circulate on social media, damaging the company's public image." },
    { icon: Users, title: "Legal Repercussions", description: "Compromised chatbots can lead to serious legal issues for companies. Incorrect or harmful responses may cause direct harm to customers or encourage employees to take illegal actions. These situations often result in lawsuits and regulatory fines. For example, when Air Canada's chatbot provided false information about refund policies, courts ordered the company to compensate affected customers. Such legal actions can be costly and further damage the company's reputation." },
  ];

  const assessmentSteps = [
    { icon: Zap, title: "Quick AI Analysis", description: "Get an initial risk assessment in minutes from our Threat Modeling Agent" },
    { icon: Shield, title: "Expert Evaluation", description: "Our security specialists thoroughly review and expand on the AI-generated report" },
    { icon: CheckCircle, title: "Tailored Protection Plan", description: "Receive customized recommendations to strengthen your chatbot's defenses" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle email submission logic here
    console.log('Email submitted:', email);
    // Reset email field after submission
    setEmail('');
  };

  return (
    <section id="attacks" className="bg-gradient-to-br from-gray-50 to-blue-100 py-16 px-4 md:px-8">
      <motion.div
        className="max-w-6xl mx-auto"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.h2 variants={itemVariants} className="text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-12 text-gray-800">
          Attackers Can Exploit These Vulnerabilities to Harm Businesses
        </motion.h2>

        <motion.div variants={itemVariants} className="grid md:grid-cols-2 gap-8 mb-12">
          {harmScenarios.map((scenario, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg p-6 border-l-4 border-red-500">
              <h3 className="text-xl md:text-2xl font-semibold mb-4 text-gray-800 flex items-center">
                <scenario.icon className="w-8 h-8 mr-3 text-red-500" />
                {scenario.title}
              </h3>
              <p className="text-gray-700 text-lg">
                {scenario.description}
              </p>
            </div>
          ))}
        </motion.div>

        <motion.div variants={itemVariants} className="bg-white rounded-lg shadow-lg p-8 mb-12">
          <h3 className="text-2xl md:text-3xl font-semibold mb-8 text-gray-800 text-center">Does your chatbot have critical vulnerabilities?</h3>

          <div className="grid md:grid-cols-2 gap-8 mb-10">
            <div className="bg-blue-50 p-6 rounded-lg flex flex-col">
              <div className="flex-grow">
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-4">
                  <h4 className="font-semibold text-xl text-gray-800 mb-2 sm:mb-0">Threat Modeling Agent</h4>
                  <div className="bg-yellow-100 text-yellow-800 px-3 py-1 rounded-full inline-block text-sm font-semibold">
                    In Preview: Launching Soon
                  </div>
                </div>
                <p className="text-gray-700 text-lg mb-4">
                  Our advanced Threat Modeling Agent will provide rapid, in-depth preliminary assessments of your chatbot's vulnerabilities.
                </p>
              </div>
              <form onSubmit={handleSubmit} className="mt-auto">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="w-full px-4 py-3 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 mb-3 text-lg"
                  required
                />
                <button
                  type="submit"
                  className="w-full bg-green-600 text-white px-6 py-3 rounded-full font-semibold text-lg hover:bg-green-700 transition duration-300 flex items-center justify-center"
                >
                  <Mail className="w-5 h-5 mr-2" />
                  Get Notified
                </button>
              </form>
            </div>
            <div className="bg-blue-50 p-6 rounded-lg flex flex-col">
              <div className="flex-grow">
                <h4 className="font-semibold text-xl mb-4 text-gray-800">Expert Risk Assessment</h4>
                <p className="text-gray-700 text-lg mb-4">
                  Our security specialists provide comprehensive evaluations and customized recommendations to strengthen your AI chatbot's defenses.
                </p>
              </div>
              <a
                href="https://cal.com/sachdh/30min"
                target="_blank"
                rel="noopener noreferrer"
                className="mt-auto w-full bg-blue-600 text-white px-6 py-3 rounded-full font-semibold text-lg hover:bg-blue-700 transition duration-300 flex items-center justify-center"
              >
                <Shield className="w-5 h-5 mr-2" />
                Schedule a Call Now
              </a>
            </div>
          </div>
          <div className="mt-10">
            <h4 className="font-semibold text-xl mb-8 text-gray-800 text-center">Our Comprehensive Assessment Process</h4>
            <div className="grid md:grid-cols-3 gap-6">
              {assessmentSteps.map((step, index) => (
                <div key={index} className="flex flex-col items-center text-center">
                  <div className="bg-blue-100 rounded-full p-4 mb-4">
                    <step.icon className="w-8 h-8 text-blue-600" />
                  </div>
                  <h5 className="font-semibold text-lg mb-3">{step.title}</h5>
                  <p className="text-base text-gray-600">{step.description}</p>
                </div>
              ))}
            </div>
          </div>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default VulnerabilityConsequencesAndSolutionsSection;
