import Header from './components/Header';
import MainBanner from './components/MainBanner';
import Footer from './components/Footer';
import SolutionsSection from './components/SolutionsSection';
import RedesignedVulnerableChatbotsSection from './components/VulnerableChatbotsSection';
import VulnerabilityConsequencesAndSolutionsSection from './components/VulnerabilityConsequencesAndSolutionsSection';


const App = () => {
  return (
    <div className="flex flex-col h-screen">
      <Header className="flex-none" />
      <div className="flex-grow overflow-auto main-content">
        <MainBanner />
        <RedesignedVulnerableChatbotsSection />
        <VulnerabilityConsequencesAndSolutionsSection />
        <SolutionsSection />
      </div>
      <Footer className="flex-none" />
    </div>
  );
};

export default App;